/**
 * Created by PanJiaChen on 16/11/18.
 */

/**
 * Parse the time to string
 * @param {(Object|string|number)} time
 * @param {string} cFormat
 * @returns {string | null}
 */
export function parseTime(time, cFormat) {
  if (arguments.length === 0 || !time) {
    return null
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if ((typeof time === 'string')) {
      if ((/^[0-9]+$/.test(time))) {
        // support "1548221490638"
        time = parseInt(time)
      } else {
        // support safari
        // https://stackoverflow.com/questions/4310953/invalid-date-in-safari
        time = time.replace(new RegExp(/-/gm), '/')
      }
    }

    if ((typeof time === 'number') && (time.toString().length === 10)) {
      time = time * 1000
    }
    date = new Date(time)
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  }
  const time_str = format.replace(/{([ymdhisa])+}/g, (result, key) => {
    const value = formatObj[key]
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') { return ['日', '一', '二', '三', '四', '五', '六'][value ] }
    return value.toString().padStart(2, '0')
  })
  return time_str
}

/**
 * @param {string} url
 * @returns {Object}
 */
export function getQueryObject(url) {
  url = url == null ? window.location.href : url
  const search = url.substring(url.lastIndexOf('?') + 1)
  const obj = {}
  const reg = /([^?&=]+)=([^?&=]*)/g
  search.replace(reg, (rs, $1, $2) => {
    const name = decodeURIComponent($1)
    let val = decodeURIComponent($2)
    val = String(val)
    obj[name] = val
    return rs
  })
  return obj
}

/**
 * @param {string} input value
 * @returns {number} output value
 */
export function byteLength(str) {
  // returns the byte length of an utf8 string
  let s = str.length
  for (var i = str.length - 1; i >= 0; i--) {
    const code = str.charCodeAt(i)
    if (code > 0x7f && code <= 0x7ff) s++
    else if (code > 0x7ff && code <= 0xffff) s += 2
    if (code >= 0xDC00 && code <= 0xDFFF) i--
  }
  return s
}

/**
 * @param {Array} actual
 * @returns {Array}
 */
export function cleanArray(actual) {
  const newArray = []
  for (let i = 0; i < actual.length; i++) {
    if (actual[i]) {
      newArray.push(actual[i])
    }
  }
  return newArray
}

/**
 * @param {Object} json
 * @returns {Array}
 */
export function param(json) {
  if (!json) return ''
  return cleanArray(
    Object.keys(json).map(key => {
      if (json[key] === undefined) return ''
      return encodeURIComponent(key) + '=' + encodeURIComponent(json[key])
    })
  ).join('&')
}

/**
 * @param {string} url
 * @returns {Object}
 */
export function param2Obj(url) {
  const search = decodeURIComponent(url.split('?')[1]).replace(/\+/g, ' ')
  if (!search) {
    return {}
  }
  const obj = {}
  const searchArr = search.split('&')
  searchArr.forEach(v => {
    const index = v.indexOf('=')
    if (index !== -1) {
      const name = v.substring(0, index)
      const val = v.substring(index + 1, v.length)
      obj[name] = val
    }
  })
  return obj
}

/**
 * @param {string} val
 * @returns {string}
 */
export function html2Text(val) {
  const div = document.createElement('div')
  div.innerHTML = val
  return div.textContent || div.innerText
}

/**
 * Merges two objects, giving the last one precedence
 * @param {Object} target
 * @param {(Object|Array)} source
 * @returns {Object}
 */
export function objectMerge(target, source) {
  if (typeof target !== 'object') {
    target = {}
  }
  if (Array.isArray(source)) {
    return source.slice()
  }
  Object.keys(source).forEach(property => {
    const sourceProperty = source[property]
    if (typeof sourceProperty === 'object') {
      target[property] = objectMerge(target[property], sourceProperty)
    } else {
      target[property] = sourceProperty
    }
  })
  return target
}

/**
 * @param {HTMLElement} element
 * @param {string} className
 */
export function toggleClass(element, className) {
  if (!element || !className) {
    return
  }
  let classString = element.className
  const nameIndex = classString.indexOf(className)
  if (nameIndex === -1) {
    classString += '' + className
  } else {
    classString =
      classString.substr(0, nameIndex) +
      classString.substr(nameIndex + className.length)
  }
  element.className = classString
}

/**
 * @param {string} type
 * @returns {Date}
 */
export function getTime(type) {
  if (type === 'start') {
    return new Date().getTime() - 3600 * 1000 * 24 * 90
  } else {
    return new Date(new Date().toDateString())
  }
}

/**
 * @param {Function} func
 * @param {number} wait
 * @param {boolean} immediate
 * @return {*}
 */
export function debounce(func, wait, immediate) {
  let timeout, args, context, timestamp, result

  const later = function() {
    // 据上一次触发时间间隔
    const last = +new Date() - timestamp

    // 上次被包装函数被调用时间间隔 last 小于设定时间间隔 wait
    if (last < wait && last > 0) {
      timeout = setTimeout(later, wait - last)
    } else {
      timeout = null
      // 如果设定为immediate===true，因为开始边界已经调用过了此处无需调用
      if (!immediate) {
        result = func.apply(context, args)
        if (!timeout) context = args = null
      }
    }
  }

  return function(...args) {
    context = this
    timestamp = +new Date()
    const callNow = immediate && !timeout
    // 如果延时不存在，重新设定延时
    if (!timeout) timeout = setTimeout(later, wait)
    if (callNow) {
      result = func.apply(context, args)
      context = args = null
    }

    return result
  }
}

/**
 * This is just a simple version of deep copy
 * Has a lot of edge cases bug
 * If you want to use a perfect deep copy, use lodash's _.cloneDeep
 * @param {Object} source
 * @returns {Object}
 */
export function deepClone(source) {
  if (!source && typeof source !== 'object') {
    throw new Error('error arguments', 'deepClone')
  }
  const targetObj = source.constructor === Array ? [] : {}
  Object.keys(source).forEach(keys => {
    if (source[keys] && typeof source[keys] === 'object') {
      targetObj[keys] = deepClone(source[keys])
    } else {
      targetObj[keys] = source[keys]
    }
  })
  return targetObj
}

/**
 * @param {Array} arr
 * @returns {Array}
 */
export function uniqueArr(arr) {
  return Array.from(new Set(arr))
}

/**
 * @returns {string}
 */
export function createUniqueString() {
  const timestamp = +new Date() + ''
  const randomNum = parseInt((1 + Math.random()) * 65536) + ''
  return (+(randomNum + timestamp)).toString(32)
}

/**
 * Check if an element has a class
 * @param {HTMLElement} elm
 * @param {string} cls
 * @returns {boolean}
 */
export function hasClass(ele, cls) {
  return !!ele.className.match(new RegExp('(\\s|^)' + cls + '(\\s|$)'))
}

/**
 * Add class to element
 * @param {HTMLElement} elm
 * @param {string} cls
 */
export function addClass(ele, cls) {
  if (!hasClass(ele, cls)) ele.className += ' ' + cls
}

/**
 * Remove class from element
 * @param {HTMLElement} elm
 * @param {string} cls
 */
export function removeClass(ele, cls) {
  if (hasClass(ele, cls)) {
    const reg = new RegExp('(\\s|^)' + cls + '(\\s|$)')
    ele.className = ele.className.replace(reg, ' ')
  }
}

// 秒转时间格式：101010 --> xx:xx:xx
export function secondToStr(seconds){
  if (seconds == 0) return `00:00:00`;
  let h = Math.floor(seconds / 60 / 60) < 10 ? '0' + Math.floor(seconds / 60 / 60) : Math.floor(seconds / 60 / 60);
  let m = Math.floor(seconds / 60 % 60) < 10 ? '0' + Math.floor(seconds / 60 % 60) : Math.floor(seconds / 60 % 60);
  let s = Math.floor(seconds % 60) < 10 ? '0' + Math.floor(seconds % 60) : Math.floor(seconds % 60);
  return `${h}:${m}:${s}`;
}

// 生成包含数字和字母的6位随机数
export const randomWord = () => {
  let code = ''
  for (var i = 0; i < 6; i++) {
    var type = getRandom(1, 3)
    switch (type) {
      case 1:
        code += String.fromCharCode(getRandom(48, 57))// 数字
        break
      case 2:
        code += String.fromCharCode(getRandom(65, 90))// 大写字母
        break
      case 3:
        code += String.fromCharCode(getRandom(97, 122))// 小写字母
        break
    }
  }
  return code
}
function getRandom (min, max) {
  return Math.round(Math.random() * (max - min) + min)
}

// 打开一个链接
export function openLink(url, target = '_self'){
  let link = document.createElement('a');
  link.setAttribute('href', url);
  link.setAttribute('target', target);
  link.setAttribute('id', 'startTelMedicine');
  if(document.getElementById('startTelMedicine')){
    document.body.removeChild(document.getElementById('startTelMedicine'));
  }
  document.body.appendChild(link);
  link.click();
}

// html 文件下载
// downloadF(html,'filename.html')//这里如果不带有后缀。默认是txt格式
export function downloadF(content, filename){
  // 创建a标签
  let linkNode = document.createElement('a');
  linkNode.download = filename;
  linkNode.style.display = 'none';
  // 利用Blob对象将字符内容转变成二进制数据
  let blob = new Blob([content]);
  linkNode.href = URL.createObjectURL(blob);
  // 点击
  document.body.appendChild(linkNode);
  linkNode.click();
  // 移除
  document.body.removeChild(linkNode);
};

/**
 * 获取图片的 base64 编码
 * @param image 图像对象
 * @return {string} 返回已编码的 base64数据
*/
 const getImageBase64 = image => {
  const canvas = document.createElement('canvas')
  canvas.width = image.width
  canvas.height = image.height
  const ctx = canvas.getContext('2d')
  ctx.drawImage(image, 0, 0, image.width, image.height)
  // 获取图片后缀名
  const extension = image.src
    .substring(image.src.lastIndexOf('.') + 1)
    .toLowerCase()
  // 某些图片 url 可能没有后缀名，默认是 png
  return canvas.toDataURL('image/' + extension, 1)
}

/**
 * 单张图片下载
 * @param url 图像链接
 * @param downloadName 图片名称
*/
export const downloadImage = (url, downloadName) => {
  const link = document.createElement('a')
  link.setAttribute('download', downloadName)
  const image = new Image()
  // 添加时间戳，防止浏览器缓存图片
  image.src = url + '?timestamp=' + new Date().getTime()
  // 设置 crossOrigin 属性，解决图片跨域报错
  image.setAttribute('crossOrigin', 'Anonymous')
  image.onload = () => {
    link.href = getImageBase64(image)
    link.click()
  }
}

// 链接截取文件后缀名
export function getExtension (name) {
  return name.substring(name.lastIndexOf("."));
}

// 链接截取文件后缀名 -- 不带点
export function getExtensionRemoveSpot (name) {
  return name.substring(name.lastIndexOf(".") + 1);
}

// 链接截取文件名
export function getFileName (name) {
  return name.substring(name.lastIndexOf("/") + 1);;
}

// 1 - 99
export function numOneToStrOne (num) {
  let strAll = ['一', '二', '三', '四', '五', '六', '七', '八', '九', '十'];
  let strOne = '';
  let numOne = String(num).split('');
  if(num <= 10){  // 1 - 10
    strOne = strAll[num - 1];
  }
  if(num >= 11 && num <= 19){ // 11 - 19
    numOne.forEach((item, index) => {
      if(index == 0) strOne += strAll[strAll.length - 1];
      if(index == 1) strOne += strAll[item - 1];
    });
  }
  if(num%10 == 0 && num > 10){  // 20, 30, ... 90
    numOne.forEach((item, index) => {
      if(index == 0) strOne += strAll[item - 1];
      if(index == 1) strOne += strAll[strAll.length - 1];
    });
  }
  if(num >= 21 && num%10 != 0){  // >= 21 && != %10
    numOne.forEach((item, index) => {
      strOne += strAll[item - 1] + (index == 0 ? '十' : '');
    });
  }
  return strOne;
}

// 小数点误差, 两位小数
export function MathRound (n, digit = 2) {
  const range = Math.pow(10, digit);
  return Math.round(n * range) / range;
};

// 金额转换
/*
// 例子
  12345格式化为12,345
  12345.6格式化为12,345.6
  12345.67格式化为 12,345.67
*/
export function Fmoney (s, n) {
  n = n > 0 && n <= 20 ? n : 2;
  s = MathRound(parseFloat((s + "").replace(/[^\d\.-]/g, "")), n) + "";
  var l = s.split(".")[0].split("").reverse(),
  r = s.split(".")[1] ? '.' + s.split(".")[1] : "", // 小数返回小数, 整数返回空
  t = "";
  for(var i = 0; i < l.length; i ++ )
    {
      t += l[i] + ((i + 1) % 3 == 0 && (i + 1) != l.length ? "," : "");
    }
  return t.split("").reverse().join("") + r;
}

// 数据去重
export function uniqueFunc(arr, uniId){
  const res = new Map();
  return arr.filter((item) => !res.has(item[uniId]) && res.set(item[uniId], 1));
}

// 将时间戳转换成日期
function add0(m) {
  return m < 10 ? '0' + m : m;
}
export function formatTime(timeStamp, str) {
  // if(typeof(timeStamp) && timeStamp != ''){
  //   return timeStamp;
  // }
  if (!timeStamp || typeof(timeStamp)=="undefined" || timeStamp==0){return timeStamp;}  // 如果是 null 不做转换
  let time = new Date(timeStamp),
      y = time.getFullYear(),
      m = time.getMonth() + 1,
      d = time.getDate(),
      h = time.getHours(),
      mm = time.getMinutes(),
      s = time.getSeconds();
  if(str === '年月日'){
    return y + '年' + add0(m) + '月' + add0(d) + '日 ' + add0(h) + ':' + add0(mm) + ':' + add0(s);
  }
  return y + '-' + add0(m) + '-' + add0(d) + ' ' + add0(h) + ':' + add0(mm) + ':' + add0(s);
}

// 转时间戳
export function timeStamp (date){
  if(!date)return date;
  return new Date(date).getTime();
}

//判断系统类型
export function OSnow(){
  var agent = navigator.userAgent.toLowerCase();
  var isMac = /macintosh|mac os x/i.test(navigator.userAgent);
  if (agent.indexOf("win32") >= 0 || agent.indexOf("wow32") >= 0) {
    //your code
    // alert("这是windows32位系统");
    return 1;  
  }
  if (agent.indexOf("win64") >= 0 || agent.indexOf("wow64") >= 0) {
    //your code
    // alert("这是windows64位系统");
    return 2;
  }
  if(isMac){
    //your code
    // alert("这是mac系统");
    return 3;
  }
}

// 清空字段
export function clearField(target, nums){
  console.log(target)
  if(!target) return target;
  let tempObj = {};
  let index = 0;
  for(let i in target){
    switch (dataType(target[i])){
      case '[object String]': tempObj[i] = ''; break;
      case '[object Array]': tempObj[i] = []; break;
      case '[object Number]': tempObj[i] = nums ? nums[index] : 0; index ++; break;
      case '[object Object]': tempObj[i] = clearField(target[i]); break;
    }
  }
  return tempObj;
}
// let obj = { num1: 1, num2: 2, age: '', name: '脏三十年', arr: [3, 6, 9], obj: { objname: 'haha', objarr: [12345], num: 10, }, }
// console.log(obj, '原对象');
// let newObj = clearField(obj, [1, 3]);
// console.log(newObj, '新对象');
// 数据类型
export function dataType(target){
  return Object.prototype.toString.call(target);
}
// console.log(Object.prototype.toString({}));//[object Object]
// console.log(Object.prototype.toString.call(''));//[object String]
// console.log(Object.prototype.toString.call(1));//[object Number]
// console.log(Object.prototype.toString.call(true));//[object Boolean]
// console.log(Object.prototype.toString.call(undefined));//[object Undefined]
// console.log(Object.prototype.toString.call(null));//[object Null]
// console.log(Object.prototype.toString.call(Symbol()));//[object Symbol]
// console.log(Object.prototype.toString.call(new Error()));//[object Error]

/*
  删除为空的字段
    搜索 --> 直接使用;
    编辑 --> 不是必须传的参数需再一一传入
*/
export function objectDeleteKey(targetObj){
  let tempObject = JSON.parse(JSON.stringify(targetObj));
  for(let i in tempObject){
    if(tempObject[i] === ''){
      delete tempObject[i];
    }
  }
  return tempObject;
}