import axios from 'axios'
import { MessageBox, Message } from 'element-ui'
import store from '@/store'
import { getToken, removeToken} from '@/utils/auth'
import router from './../router'
// create an axios instance
const service = axios.create({
  baseURL:process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 18000 // request timeout
})
console.log(process, process.env, process.env.VUE_APP_BASE_API, '============')

const whitelist = ['customer/getWechatQtCode']; // 定义白名单

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent

    if (store.getters.token) {
      // let each request carry token
      // ['X-Token'] is a custom headers key
      // please modify it according to the actual situation
      config.headers['token'] = getToken()
    }
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const res = response.data

    if (whitelist.indexOf(response.config.url) === -1) { // 当前请求不在白名单之中，进行拦截

      // if the custom code is not 20000, it is judged as an error.
      if (res.code !== 0) {
        let _redirect = router.currentRoute.query.redirect ? router.currentRoute.query.redirect : router.currentRoute.fullPath
        // 1017 用户登录已超时
        // 1018 短信验证码错误或者失效
        // 1025 用户不存在
        // 1024 尚未设置密码, 请使用验证码登录

        if(res.code == 1017){ // 用户登录已超时
          router.push(`/login?redirect=${_redirect}`);
          store.dispatch('user/outLogin');
        }
        store.dispatch('user/setCode', res.code);
        Message({
          message: res.message || 'Error',
          type: 'error',
        })
        return Promise.reject(new Error(res.message || 'Error'))
      } else {
        return res
      }

    } else {
      return res
    }
  },
  error => {
    console.log('err' + error) // for debug
    // Message({
    //   message: `出错了：${error.message}`,
    //   type: 'error',
    //   duration: 5 * 1000
    // })
    return Promise.reject(error)
  }
)

export default service
