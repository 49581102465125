import { getAccountInfo, mobileLogin } from '@/api/user'; // 用户信息, 手机号密码登录
import { getToken, setToken, removeToken } from '@/utils/auth'
import router, { resetRouter } from '@/router'
import md5 from 'js-md5';
const state = {
  token: getToken(),
  nickname: '',
  headImage: '',
  studyNo: '',
  customerId: '',
  whiteUser: '',  // 是否白名单用户
  hasbindWechat: '',  // 手机号是否绑定微信 1是 0否
  sex: '',  // 性别 1男 2女 0保密
  birthday: '', // 生日
  province: '', // 地区-省
  city: '', // 地区-市
  mobile: '', // 手机号
  password: '', // 密码
  code: 0,
}

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_NICKNAME: (state, nickname) => {
    state.nickname = nickname
  },
  SET_HEADIMAGE: (state, headImage) => {
    state.headImage = headImage
  },
  SET_STUDYNO: (state, studyNo) => {
    state.studyNo = studyNo
  },
  SET_CUSTOMERID: (state, customerId) => {
    state.customerId = customerId
  },
  SET_WHITEUSER: (state, whiteUser) => {
    state.whiteUser = whiteUser
  },
  SET_HASBINGWRCHAT: (state, hasbindWechat) => {
    state.hasbindWechat = hasbindWechat
  },
  SET_SEX: (state, sex) => {
    state.sex = sex
  },
  SET_BIRTHDAY: (state, birthday) => {
    state.birthday = birthday
  },
  SET_PROVINCE: (state, province) => {
    state.province = province
  },
  SET_CITY: (state, city) => {
    state.city = city
  },
  SET_MOBILE: (state, mobile) => {
    state.mobile = mobile
  },
  SET_PASSWORD: (state, password) => {
    state.password = password
  },
  SET_CODE: (state, code) => {
    state.code = code
  },
}

const actions = {
  // 手机号验证码|密码登录
  mobileLogin({ commit, dispatch }, userInfo) {
    const { loginType, mobile, smsCode, password } = userInfo
    return new Promise((resolve, reject) => {
      let params = {};
      if(loginType == 1){ // 验证登录
        params = { loginType: loginType, mobile: mobile, smsCode: smsCode };
      }
      if(loginType == 2){ // 密码登录
        params = { loginType: loginType, mobile: mobile, password: password };
      }
      mobileLogin(params).then(res => {
        dispatch('setLoginInfo', res);
        resolve(res);
      }).catch(error => {
        reject(error);
      })
    })
  },

  // 登录后设置信息
  setLoginInfo({ commit, state, dispatch }, res) {
    commit('SET_TOKEN', res.data.token);
    commit('SET_CUSTOMERID', res.data.customerId);
    commit('SET_WHITEUSER', res.data.whiteList);
    commit('SET_HASBINGWRCHAT', res.data.hasbindWechat);

    commit('SET_NICKNAME', res.data.nickname);
    commit('SET_HEADIMAGE', res.data.headImage);
    commit('SET_STUDYNO', res.data.studyNo);

    setToken(res.data.token);
  },

  // 退出登录
  outLogin({ commit, state, dispatch }) {
    removeToken();
    commit('SET_TOKEN', '');
    commit('SET_CUSTOMERID', '');
    commit('SET_WHITEUSER', '');
    commit('SET_HASBINGWRCHAT', '');
    commit('SET_NICKNAME', '');
    commit('SET_HEADIMAGE', '');
    commit('SET_STUDYNO', '');
    commit('SET_MOBILE', '');
    commit('SET_SEX', '');
    commit('SET_BIRTHDAY', '');
    commit('SET_PROVINCE', '');
    commit('SET_CITY', '');
  },

  // get user info
  getAccountInfo({ commit, state }) {
    return new Promise((resolve, reject) => {
      getAccountInfo().then(res => {

        commit('SET_HASBINGWRCHAT', res.data.hasbindWechat);
        commit('SET_MOBILE', res.data.mobile);
        commit('SET_PASSWORD', res.data.password);
        commit('SET_STUDYNO', res.data.studyNo);

        commit('SET_NICKNAME', res.data.appNickname);
        commit('SET_HEADIMAGE', res.data.appHeadImage);
        commit('SET_SEX', res.data.sex);
        commit('SET_BIRTHDAY', res.data.birthday);
        commit('SET_PROVINCE', res.data.province);
        commit('SET_CITY', res.data.city);

        resolve(res.data);
      }).catch(error => {
        reject(error);
      })
    })
  },

  setCode({ commit }, code) {
    commit('SET_CODE', code)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
