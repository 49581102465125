import request from "@/utils/request";

// 用户信息
export function getAccountInfo(data) {
  return request({
    url: 'personal/getAccountInfo',
    method: 'post',
    data,
  })
}
// 手机号密码登录
export function mobileLogin(data) {
  return request({
    url: 'customer/mobileLogin',
    method: 'post',
    data,
  })
}
// 上传头像
export function imageUpload(data) {
  return request({
    url: 'upload/imageUpload',
    method: 'post',
    data,
  })
}
// 编辑个人资料
export function modifyAccountInfo(data) {
  return request({
    url: 'personal/modifyAccountInfo',
    method: 'post',
    data,
  })
}
// 绑定手机号
export function bindMobile(data) {
  return request({
    url: 'personal/bindMobile',
    method: 'post',
    data,
  })
}
// 更换手机号
export function changeMobile(data) {
  return request({
    url: 'personal/changeMobile',
    method: 'post',
    data,
  })
}
// 获取验证码
export function sendSmsCode(data) {
  return request({
    url: 'common/sendSmsCode',
    method: 'post',
    data,
  })
}
// 设置|更改密码
export function changePassword(data) {
  return request({
    url: 'personal/changePassword',
    method: 'post',
    data,
  })
}
// 重置密码
export function resetPassword(data) {
  return request({
    url: 'customer/resetPassword',
    method: 'post',
    data,
  })
}
// 重置密码 -- 校验验证码
export function checkSmsCode(data) {
  return request({
    url: 'common/checkSmsCode',
    method: 'post',
    data,
  })
}
// 获取微信登录二维码
export function getWechatQtCode(data) {
  return request({
    url: 'customer/getWechatQtCode',
    method: 'post',
    data,
  })
}
// 微信登录
export function wechatLogin(data) {
  return request({
    url: 'customer/wechatLogin',
    method: 'post',
    data,
  })
}
// 绑定登录
export function bindWechat(data) {
  return request({
    url: 'customer/bindWechat',
    method: 'post',
    data,
  })
}