import Cookies from 'js-cookie'
import { getSession, setSession } from '@/utils/auth';

const state = {
  allPackageList: [],  // 所有课程包列表
  curPackageList: [],  // 当前课程包列表
  allPackageStatus: [{ code: 1, desc: '待开课' }, { code: 2, desc: '上课中' }, { code: 3, desc: '已结课' }, { code: 4, desc: '已过期' }],  // 1:待开课 2:上课中 3:已结课 4:已过期
  curPackageStatus: [],
  packageTypeCode: [],  // 课程包业务类型

  courseBreadcrumb: getSession('courseBreadcrumb') ? getSession('courseBreadcrumb') : [], // 我的课程 -- 面包屑
  LoginPage: 0, // 登录页面
}

const mutations = {
  SET_ALLPACKAGELIST: (state, allPackageList) => {
    state.allPackageList = allPackageList;
  },
  SET_CURPACKAGELIST: (state, curPackageList) => {
    state.curPackageList = curPackageList;
  },
  SET_CURPACKAGESTATUS: (state, curPackageStatus) => {
    state.curPackageStatus = curPackageStatus;
  },
  SET_PACKAGETYPECODE: (state, packageTypeCode) => {
    state.packageTypeCode = packageTypeCode;
  },

  SET_COURSEBREADCRUMB: (state, courseBreadcrumb) => {
    state.courseBreadcrumb = courseBreadcrumb;
  },
  SET_LOGINPAGE: (state, LoginPage) => {
    state.LoginPage = LoginPage;
  },
  
}

const actions = {
  allPackageList({ commit }, data) {
    commit('SET_ALLPACKAGELIST', data);
  },
  curPackageList({ commit }, data) {
    commit('SET_CURPACKAGELIST', data);
  },
  curPackageStatus({ commit }, data) {
    commit('SET_CURPACKAGESTATUS', data);
  },
  packageTypeCode({ commit }, data) {
    commit('SET_PACKAGETYPECODE', data);
  },

  courseBreadcrumb({ commit }, data) {
    commit('SET_COURSEBREADCRUMB', data);
    setSession('courseBreadcrumb', data);
  },
  LoginPage({ commit }, data) {
    commit('SET_LOGINPAGE', data);
  },
  
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
