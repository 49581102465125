<template>
  <div class="header">
    <div class="loader-wrapper" v-if="loading">
      <div class="loader"></div>
    </div>
    <div class="x-wrap">
      <div class="logo-nav">
        <div class="logo pic"><img src="@/assets/img/logo.jpg" alt="" class="i"></div>
        <div class="nav" v-if="$route.path != '/login'">
          <el-menu :default-active="activeMenu" text-color="#3C3C3C" :unique-opened="false" active-text-color="#FF6315" mode="horizontal">
            <sidebar-item v-for="route in routes" :key="route.path" :item="route" :base-path="route.path" class="item" />
          </el-menu>
        </div>
      </div>
      <div class="right-menu" v-if="$route.path == '/login'">
        <div @click="$router.push('/');" style=" font-size: 18px; font-weight: 500; color: #3C3C3C; cursor: pointer; line-height: 56px; ">返回首页</div>
      </div>
      <div class="right-menu" v-if="$route.path != '/login'">
        <div class="login-btn" v-if="!token">
          <div class="login btn" @click="toLogin">注册/登录</div>
          <div class="login shop" @click="openShop">商城采购</div>
          <!-- <div class="register btn">注册</div> -->
        </div>
        <div class="user-info" v-if="token">
          <div class="meta">
            <div class="nickname">{{ nickname }}</div>
            <div class="clickImg" @click.stop="dropDownMenu">
              <div class="headImage pic">
                <img :src="headImage" alt="" class="i">
              </div>
              <div class="icon-down">
                <i class="el-icon-arrow-down"></i>
              </div>
            </div>
          </div>
          <div class="dropdown-menu" :class="{show: isShowDropDownMenu}">
            <div class="li" @click="toUserInfo">
              <div class="icon pic">
                <img src="@/assets/img/icon_006.png" alt="" class="i">
                <img src="@/assets/img/icon_006_h.png" alt="" class="i h">
              </div>
              <div class="txt">个人信息</div>
            </div>
            <div class="li" @click="toMyCourse">
              <div class="icon pic">
                <img src="@/assets/img/icon_007.png" alt="" class="i">
                <img src="@/assets/img/icon_007_h.png" alt="" class="i h">
              </div>
              <div class="txt">我的课程</div>
            </div>
            <div class="li" @click="toOutLogin">
              <div class="icon pic">
                <img src="@/assets/img/icon_008.png" alt="" class="i">
                <img src="@/assets/img/icon_008_h.png" alt="" class="i h">
              </div>
              <div class="txt">退出登录</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import path from 'path';
import { removeToken } from '@/utils/auth'
import { isExternal } from '@/utils/validate'
import { mapGetters } from 'vuex'
import SidebarItem from './SidebarItem'
import { getPlatUrl } from "@/api/course"
export default {
  components: { SidebarItem },
  data(){
    return {
      loading: false,
      isShowDropDownMenu: false,
    }
  },
  computed: {
    ...mapGetters([
      'permission_routes',
      'token',
      'nickname',
      'headImage'
    ]),
    // 所有路由
    routes() {
      return this.$store.state.permission.routes
    },
    // 当前导航
    activeMenu() {
      const route = this.$route;
      const { path } = route;
      return path;
    },
  },
  mounted(){
    document.addEventListener('click', this.documentClick);
  },
  methods: {
    hasOneShowingChild(children = [], parent) {
      const showingChildren = children.filter(item => {
        if (item.hidden) {
          return false
        } else {
          return true
        }
      })

      // When there is only one child router, the child router is displayed by default
      if (showingChildren.length === 1) {
        return true
      }

      return false
    },
    resolvePath(routePath) {
      if (isExternal(routePath)) {
        return routePath
      }
      if (isExternal(this.basePath)) {
        return this.basePath
      }
      console.log(this.basePath, routePath, path.resolve(this.basePath, routePath))
      return path.resolve(this.basePath, routePath)
    },
    // 个人菜单下拉
    dropDownMenu(){
      this.isShowDropDownMenu = !this.isShowDropDownMenu;
    },
    documentClick(){
      this.isShowDropDownMenu = false;
    },
    // 个人信息
    toUserInfo() {
      this.$router.push({path: '/personManage', query: { curMent: 1 }});
    },
    // 我的课程
    toMyCourse() {
      this.$router.push({path: '/personManage', query: { curMent: 2 }});
    },
    // 退出登录
    toOutLogin() {
      this.$store.dispatch('user/outLogin');
      this.$store.commit('permission/SET_ROUTES', []);
      this.$store.dispatch("app/courseBreadcrumb", []);
      this.$router.push("/");
      // this.$router.push(`/login?redirect=${this.$route.query.redirect ? this.$route.query.redirect : this.$route.fullPath}`);
    },
    // 登录
    toLogin() {
      this.$router.push(`/login?redirect=${this.$route.query.redirect ? this.$route.query.redirect : this.$route.fullPath}`);
    },
    // 打开商城
    openShop() {
      this.loading = true;  
      getPlatUrl({version:"NW93jBJtKDLW4oEI",versionSecret:"123456"}).then(res => {
          if(res.code == 0) {
            var url = res.data;
            window.open(url, '_blank');
            this.loading = false;
          }
        });
    }
  }
}
</script>

<style lang="scss" scoped>
.loader-wrapper {
  position: fixed;   /* 固定定位，使其始终位于视图窗口 */
  top: 0;
  left: 0;
  width: 100%;       /* 覆盖整个视口宽度 */
  height: 100%;      /* 覆盖整个视口高度 */
  background-color: rgba(255, 255, 255, 0.8); /* 半透明白色背景 */
  display: flex;
  justify-content: center;  /* 水平居中 */
  align-items: center;      /* 垂直居中 */
}

.loader {
  border: 5px solid #f3f3f3; /* Light grey border */
  border-top: 5px solid #3498db; /* Blue border */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
}
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.header{
  position: sticky; top: 0; z-index: 99; background-color: rgba(#FFFFFF, .85);
  padding: 12px 0; box-shadow: 0px 2px 10px 0px rgba(0,0,0,0.05);
  .x-wrap{ display: flex; justify-content: space-between; align-content: center; }
  .logo-nav{ display: flex; align-content: center; }
  .logo, .nav{ display: inline-block; vertical-align: middle; }
  .logo{ width: 163px; }
  .nav{
    .el-menu{ background-color: transparent; }
    .el-menu.el-menu--horizontal{ border: 0; }
    margin-left: 134px;
    .item{ display: inline-block; vertical-align: middle; margin: 0 40px; }
    ::v-deep .el-menu-item{
      padding-left: 0; padding-right: 0;
      font-size: 18px;
    }
    ::v-deep .el-menu-item:hover{
      background-color: transparent; color: #FF6315 !important;
      border-bottom: 2px #FF6315 solid !important;
    }
    ::v-deep .router-link-exact-active{
      .el-menu-item{
        background-color: transparent;
        display: inline-block; border-bottom-width: 2px; border-bottom-style: solid;
      }
    }
  }
  .right-menu{
    display: flex; align-items: center;
    .login-btn, .user-info{ display: inline-block; vertical-align: middle; }
    .login-btn{
      
      .btn{
        display: inline-block; vertical-align: middle;
        width: 73px; height: 26px; background: #FF6000; border-radius: 14px; border: 1px solid #FF6000; color: #FFFFFF; font-size: 16px; text-align: center; line-height: 26px; cursor: pointer;
        &.login{
          // background-color: #FFFFFF; color: #FF6315; margin-right: 10px;
          height: 32px; line-height: 32px; padding: 0 22px; border-radius: 32px;
        }
      }

      .shop{
        display: inline-block; vertical-align: middle;
        margin-left: 5px;
        width: 73px; height: 26px; background: #FF6000; border-radius: 14px; border: 1px solid #FF6000; color: #FFFFFF; font-size: 16px; text-align: center; line-height: 26px; cursor: pointer;
        &.login{
          // background-color: #FFFFFF; color: #FF6315; margin-right: 10px;
          height: 32px; line-height: 32px; padding: 0 22px; border-radius: 32px;
        }
      }
    }
    .user-info{
      position: relative; 
      .nickname, .clickImg, .headImage, .icon-down{ display: inline-block; vertical-align: middle; }
      .nickname{ font-size: 16px; font-weight: 500; color: #3C3C3C; line-height: 44px; }
      .headImage{ width: 36px; height: 36px; border-radius: 50%; margin-left: 10px; overflow: hidden; }
      .icon-down{ margin-left: 14px; }
      .clickImg{ cursor: pointer; }
      .dropdown-menu{
        position: absolute; top: 48px; right: -5px; padding: 10px 0;
        background: #FFFFFF; box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.1); border-radius: 8px; box-sizing: border-box;

        height: 0; padding: 0; transition: all .3s; overflow: hidden;
        &.show{ padding: 10px 0; height: 152px; }

        .li{
          margin: 0 5px; padding: 9px 17px; cursor: pointer; border-radius: 4px; width: 76px; text-align: center;
          &+.li{ margin-top: 5px; }
          .pic .i{ display: block; }
          .pic .h{ display: none; }
          &:hover{
            background-color: #D8D8D8;
            .pic .i{ display: none; }
            .pic .h{ display: block; }
            .txt{ color: #3C3C3C; }
          }
        }
        .icon, .txt{ display: inline-block; vertical-align: middle; }
        .icon{ width: 14px; }
        .txt{ margin-left: 4px; font-size: 14px; font-weight: 500; color: #7C7C7C; line-height: 20px; }

      }
      
    }
  }
}
</style>