import request from "@/utils/request";

// 课程包列表
export function getMyCourse(data) {
  return request({
    url: 'studycenternew/getMyCourse',
    method: 'post',
    data,
  })
}
// 补充收货地址
export function editAddress(data) {
  return request({
    url: 'studycenternew/editAddress',
    method: 'post',
    data,
  })
}
// 获取开课时间
export function getCouseTime(data) {
  return request({
    url: 'studycenternew/getCouseTime',
    method: 'post',
    data,
  })
}
// 提交开课时间
export function selectCourseTime(data) {
  return request({
    url: 'studycenternew/selectCourseTime',
    method: 'post',
    data,
  })
}
// 获取签合同二维码
export function findQrCodeUrl(query) {
  return request({
    url: 'qrcode/findQrCodeUrl',
    method: 'get',
    params: query
  })
}
// 获取小白课程
export function getXbCourseInfo(data) {
  return request({
    url: 'studycenternew/getXbCourseInfo',
    method: 'post',
    data,
  })
}
// 获取大课课程 -- 头部
export function findCourseByCustomerIdAndPackageId(data) {
  return request({
    url: 'studycenternew/findCourseByCustomerIdAndPackageId',
    method: 'post',
    data,
  })
}
// 获取大课课程 -- 列表
export function getBigCourseInfo(data) {
  return request({
    url: 'studycenternew/getBigCourseInfo',
    method: 'post',
    data,
  })
}
// 获取直播 -- 列表
export function getLiveListApp(data) {
  return request({
    url: 'studycenternew/getLiveListApp',
    method: 'post',
    data,
  })
}
// 获取批次
export function getBatchInfo(data) {
  return request({
    url: 'studycenternew/getBatchInfo',
    method: 'post',
    data,
  })
}
// 获取直播状态切换
export function findLiveStatus(data) {
  return request({
    url: 'studycenternew/findLiveStatus',
    method: 'post',
    data,
  })
}
// 获取商城链接
export function getPlatUrl(data) {
  return request({
    url: 'basic/getPlatUrl',
    method: 'post',
    data,
  })
}