<template>
  <div class="app-wrapper">
    <Header />
    <keep-alive :include="['Home', 'Download', 'About']">
      <router-view class="main" />
    </keep-alive>
    <Footer />
  </div>
</template>

<script>
import { Header } from "./Header";
import Footer from "./Footer";
export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {};
  },
  // provide() {
  //   return {
  //     isplays: this.$refs.commonplayer.isplay
  //   }
  // },
  computed: {},
  created() {},
  mounted() {},
  methods: {
    
  },
  beforeDestroy() {},
};
</script>

<style lang="scss" scoped>
.main {
  width: 100%;
  min-height: calc(100vh - 80px - 228px);
}
</style>

