const getters = {
  permission_routes: state => state.permission.addRoutes,

  token: state => state.user.token,
  nickname: state => state.user.nickname,
  headImage: state => state.user.headImage,
  studyNo: state => state.user.studyNo,
  sex: state => state.user.sex,
  birthday: state => state.user.birthday,
  province: state => state.user.province,
  city: state => state.user.city,
  mobile: state => state.user.mobile,
  password: state => state.user.password,
  hasbindWechat: state => state.user.hasbindWechat,
  
  errorLogs: state => state.errorLog.logs,
  
  code: state => state.user.code,

  allPackageList: state => state.app.allPackageList,
  curPackageList: state => state.app.curPackageList,
  allPackageStatus: state => state.app.allPackageStatus,
  curPackageStatus: state => state.app.curPackageStatus,
  packageTypeCode: state => state.app.packageTypeCode,

  courseBreadcrumb: state => state.app.courseBreadcrumb,
  LoginPage: state => state.app.LoginPage,
}
export default getters
