import Cookies from 'js-cookie'

const TokenKey = 'YC-Token'

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

export function getCookies(key) {
  return Cookies.get(key)
}

export function setCookiesDay(key, value, expiresDay) {  // 几天后失效
  Cookies.set(key, value, { expires: expiresDay });
}

export function setCookiesTime(key, value, expiresTime) { // 几秒后失效
  let seconds = expiresTime
  let expires = new Date(new Date() * 1 + seconds * 1000)
  return Cookies.set(key, value, { expires: expires })
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}
export function setSession(key, palyload) {
  sessionStorage[key] = JSON.stringify(palyload);
}
export function getSession(key) {
  return sessionStorage[key] && sessionStorage[key] != 'undefined' ? JSON.parse(sessionStorage[key]) : "";
}