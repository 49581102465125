import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import 'animate.css';
import '@/scss/index.scss' // global css

import * as filters from "./filters";

import utils from "./utils";

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import './permission' // permission control

Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key]);
});

Vue.use(ElementUI);

Vue.config.productionTip = false;

// 注册到vue原型上;
Vue.prototype.utils=utils;


new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

console.log('这是一个最好的时代，科技的发展给予了每个人创造价值的可能性；这也是一个最充满想象的时代，每一位心怀梦想的人，终会奔向星辰大海。 易橙与你们一起仰望星辰大海，携手共筑未来！');

//去掉分享链接中的token，防止用户信息错乱
function clearShareUrlToken(shareUrl) {
  var url = shareUrl.replace("&token=", "&userTk=")
    .replace("?token=", "?userTk=")
    .replace("%26token%3D", "%26userTk%3D")
    .replace("%3Ftoken%3D", "%3FuserTk%3D");
  return url;
}

const ua = navigator.userAgent;
Vue.prototype.$isIOS = !!ua.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
