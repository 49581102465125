import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

import Layout from '@/layout';

// 固定的路由
export const constantRoutes = [
  {
    path: '/',
    component: Layout,
    children: [
      {
        path: "",
        name: "Home",
        meta: {
          title: "首页",
        },
        component: () => import(/*webpackChunkName: "index"*/ "@/views/home/index"),
      },
    ]
  },
  {
    path: '/download',
    component: Layout,
    children: [
      {
        path: "",
        name: "Download",
        meta: {
          title: "下载App",
        },
        component: () => import(/*webpackChunkName: "download"*/ "@/views/download/index"),
      },
    ]
  },
  {
    path: '/about',
    component: Layout,
    children: [
      {
        path: "",
        name: "About",
        meta: {
          title: "关于易橙",
        },
        component: () => import(/*webpackChunkName: "about"*/ "@/views/about/index"),
      },
    ]
  },
  {
    path: "/login",
    component: Layout,
    hidden: true,
    children: [
      {
        path: "",
        name: "Login",
        meta: {
          title: "登录",
        },
        component: () => import(/*webpackChunkName: "login"*/ "@/views/login/index"),
      },
    ]
  },
  {
    path: "/contract",
    name: "Contract",
    meta: {
      title: "合同模板",
    },
    component: () => import(/*webpackChunkName: "contract"*/ "@/views/contract"),
    hidden: true,
  },
  {
    path: "/contractView",
    name: "ContractView",
    meta: {
      title: "合同模板预览",
    },
    component: () => import(/*webpackChunkName: "contract"*/ "@/views/contract/view"),
    hidden: true,
  },
  {
    path: '/404',
    // component: () => import('@/views/error-page/404'),
    component: () => import(/*webpackChunkName: "contract"*/ "@/views/error-page/404"),
    hidden: true
  },
];

// 登录后才能看到的路由
export const asyncRoutes = [
  {
    path: "/personManage",
    component: Layout,
    hidden: true,
    children: [
      {
        path: "",
        name: "PersonManage",
        meta: {
          title: "我的管理",
        },
        component: () => import(/*webpackChunkName: "contract"*/ "@/views/personManage"),
      },
    ]
  },

  // 404 page must be placed at the end !!!
  {
    path: '*',
    redirect: '/404',
    hidden: true
  }
];

const router = new VueRouter({
  mode: "history",
  // base: process.env.BASE_URL,
  routes: constantRoutes,
});

router.afterEach(() => { });

export default router;
